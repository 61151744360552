
.exam-results {
    display: flex;
    max-width: 800px;
    flex-direction: column;
    margin: auto;
    padding: 20px;
    background: #ffffff;
    gap: 20px;
}

.result-questions {
    display: flex;
    flex-direction: column;
}

.result-question {
    padding: 20px 0;
    border-bottom: solid 1px #efefef;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.result-question p:first-of-type {
    font-weight: bold;
}

.result-question p:nth-of-type(even) {
    text-decoration: underline;
    margin-top: 8px;
}


.return-button {
    background-color: #007bff; 
    color: #fff; 
    border: none;
    padding: 10px 20px; 
    border-radius: 4px; 
    font-size: 16px; 
}

