.forum {
    display: flex;
    max-width: 800px;
    margin: auto;
}

.forum-posts-empty {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forum-posts {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: #ffffff;
    border-bottom-right-radius: 16px;
}

.forum-posts-header {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 0 16px 16px;
    justify-content: space-between;
}

.forum-posts-header > p {
    font-size: 18px;
    font-weight: bold;
}

.forum-post {
    padding: 16px;
    border-radius: 16px;
    transition: background 200ms;
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    color: black;
    z-index: 1;
}

.create-post-button {
    align-self: flex-start;
    border-radius: 16px;
    width: 100px;
}

.create-post-form {
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    z-index: 2;
}

.create-post-control {
    display: flex;
    gap: 8px;
    align-items: center;
    padding-bottom: 16px;
}

.create-post-control input {
    border-radius: 16px;
}

.create-post-control button {
    border-radius: 16px;
}

.forum-post:hover {
    background: #e9ecee;
    color: black;
}

.forum-post a {
    z-index: 2;
}

.post-title {
    font-weight: bold;
    font-size: 16px;
}

.post-preview {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.post-preview img {
    max-width: 400px;
}

.comment-count {
    margin-top: 8px;
}

.post-divider {
    width: calc(100% - 32px);
    min-width: 0;
    margin: 0 auto;
    border-color: #efefef;
}

.post {
    max-width: 800px;
    margin: auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: #ffffff;
    border-radius: 0 0 16px 16px;
}

.post-header {
    display: flex;
    gap: 16px;
    align-items: center;
}

.post-back-button {
    align-self: flex-start;
    border-radius: 16px;
}

.post-header p {
    font-weight: bold;
    font-size: 18px;
}

.post-main {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.post-main-title {
    font-size: 18px;
    font-weight: bold;
}

.post-replies {
    background: #f0f0f0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
}

.post-reply-header {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.post-reply-header .ant-select-selection-search-input:hover {
    color: #ffffff !important;
}

.reply-control {
    display: flex;
    gap: 16px;
}

.reply-control > button {
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
}

.post-reply-header p {
    font-weight: bold;
    font-size: 16px;
}

.post-reply {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.post-reply-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.post-reply-empty p {
}

.editor {
    background: white;
}

.editor-hidden {
    display: none;
}

.post-main-content img,
.post-reply-content img {
    max-width: 400px;
}

.post-preview a,
.post-main-content a,
.post-reply-content a {
    color: #722ed1;
}

.post-preview a:hover,
.post-main-content a:hover,
.post-reply-content a:hover {
    color: #22075e;
    text-decoration: underline;
}


/*:where(.css-dev-only-do-not-override-1j0l7mx) .ant-menu-light .ant-menu-item-selected {*/
/*    background-color: #babab8;*/
/*    !* Add any other styling you want for the selected item *!*/
/*}*/