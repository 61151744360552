
.homeTitle {
    text-align: center;
    margin: 100px auto 50px;

}

.card-design {
    background-color: #52826B;
}

.home-cards {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

@media (max-width: 1050px) {
    .home-cards {
        flex-direction: column;
    }
}
