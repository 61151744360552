.modules {
    max-width: 800px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    justify-content: center;
    grid-gap: 20px;
    padding: 20px;
}

.module {
    height: 200px;
    border-radius: 16px;
    overflow: hidden;
    transition: box-shadow 200ms;
    color: white;
    background-color: #52826B;
}

.module:hover {
    box-shadow: 1px 1px 5px #aaaaaa;
    color: #343431;
}


.module-details {
    padding: 10px;

}

.module-details > p:first-of-type {
    font-weight: bold;
}

.module-code {
    font-size: x-large;
    border-bottom: 2px dashed white;
    padding-top: 30px;
    padding-bottom: 10px;
}

.module-name {
    margin-top: 20px;
}