.exam-questions {
  padding: 20px; /* Add desired padding to create spacing */
}

.question-heading {
  margin-bottom: 20px; /* Add desired margin between heading and form */
}

.question-container {
  margin-bottom: 20px; /* Add desired margin between questions */
}

.question-text {
  margin-bottom: 10px; /* Add margin between question and label */
}

.answer-input {
  display: block; /* Display the input below the label */
}

.countdown-timer {
  font-size: 25px; /* Increase the text size */
  padding: 10px; /* Add padding to give it more space */
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
}

.question-text {
  font-weight: bold;
}
