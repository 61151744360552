
.revision {
    max-width: 800px;
    margin: auto;

}

.revision-header {
    font-size: 20px;
    font-weight: bold;
}

.revision-topic {
    background: #52826B;
    color: white;
    padding: 0 !important;
    transition: background 200ms;
}

.revision-topic:hover {
    background: #eaeaea;
}

.revision a {
    width: 100%;
    padding: 12px 12px;
}

.revision a:hover {
    color: #106ac7;
}