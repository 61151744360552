.module-topic {
    text-align: center;
    padding: 20px;
}

.flashcard-content {
    height: 100%;
}

.flashcard {
    background-color: #52826B;
    border-radius: 14px;
    width: 60vh;
    height: 60vh;
    min-height: 420px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    perspective: 1000px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    cursor: pointer;
}

.flashcard-question {
    text-align: center;
    padding: 20px;
    color: white;
}

.flashcard-question-text {
    padding: 20px;
    color: white;
}

.flashcard-reveal-prompt {
    color: #343431;
}

.flashcard-answer {
    text-align: center;
    padding: 20px;
    color: white;
}

.flashcard-answer-text {
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flashcard-reveal {
    text-align: center;
    padding: 20px;
}

.rate-question {
    text-align: center;
    padding: 20px;
}

.number-scale {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
}

.flashcard-navigation {
    text-align: center;
    padding: 20px;
}

.done-button {
    text-align: center;
}

.mastery-percentage {
    text-align: center;
    padding: 50px;

}

.mastery-message {
    padding: 20px;
}

.retake {
    padding: 20px;
}

.flashcard-flip {
    transform: rotateY(180deg);
}

.front, .back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    position: absolute;
    color: white;
}

.front {
    z-index: 2;
    padding: 30px;
}

.back {
    transform: rotateY(180deg);

}

.alert-message {
    padding: 20px;
}