.ViewResults-button {
    padding: 2px 15px;
    background-color: green;
    color: white;
    &:hover {
      background-color: lightgreen; 
      color: white;
  }
}

.exam-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.completed-button {
  background-color: #007bff;
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 16px;
  cursor: not-allowed;
}
