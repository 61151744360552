
.login {
  height: 100vh;
  overflow: hidden;
  background: url("../images/login.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  justify-content: center;
}

.login-form h1, .login-form label {
  color: white! important;
}

.login-error {
  background: rgba(255, 0, 0, 0.5);
  color: white;
  padding: 4px 11px;
  border-radius: 6px;
  max-height: 40px;
}

.error-hidden {
  display: none;
}

.login-button {
  display: flex;
  justify-content: center;
}

@media (max-width: 575px) {
  .ant-col-offset-6 {
    margin-inline-start: 0;
  }
}

